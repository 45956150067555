import constants from '@/common/constant/constants'
import { SysMenu } from '../menu/types'
import { localCache } from '@/utils/cache'

/**
 * 校验操作权限
 * @param promissionStr
 * @returns
 */
const checkAuth = (promissionStr:string):boolean => {
  let promissions:string[] = localCache.getSingleStorage(constants.PROMISSIONS)
  if (promissions === undefined || promissions === null || promissions.length === 0) {
    promissions = []
    const menus:SysMenu[] = localCache.getSingleStorage(constants.ALL_MENU_CACHE)
    if (menus !== null && menus.length > 0) {
      menus.forEach((item, index) => {
        if (item.isShow === '0') {
          if (item.promission !== undefined && item.promission !== null && item.promission.length > 0) {
            promissions.push(item.promission)
          }
        }
      })
    }
    localCache.setSingleStorage(constants.PROMISSIONS, promissions)
  }
  if (promissions.length > 0) {
    return promissions.includes(promissionStr)
  } else {
    return false
  }
}
export default checkAuth
