import { message } from 'ant-design-vue'

class Common {
  // 时间戳（精确到秒，10位）转为'xx年xx月xx日'
  timestampToDate = (timestamp: string): string => {
    const _timestamp = parseInt(timestamp)
    const myDate = new Date(_timestamp * 1000)
    // const year = date.getFullYear()
    // const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    // const day = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()
    // const str = `${year}年${month}月${day}日`

    const year = myDate.getFullYear() // 获取当前年
    const mon = myDate.getMonth() + 1 // 获取当前月
    const date = myDate.getDate() // 获取当前日
    const hours = myDate.getHours() // 获取当前小时
    const minutes = myDate.getMinutes() // 获取当前分钟
    const seconds = myDate.getSeconds() // 获取当前秒

    let strMon = mon.toString()
    if (strMon.length === 1) {
      strMon = '0' + strMon
    }
    let strDate = date.toString()
    if (strDate.length === 1) {
      strDate = '0' + strDate
    }

    const now = year + '-' + strMon + '-' + strDate + ' ' + hours + ':' + minutes + ':' + seconds
    return now
  }

  getBase64 = (img: Blob, callback: (base64Url: string) => void) => {
    const reader = new FileReader()
    // eslint-disable-next-line node/no-callback-literal
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  objectToFormData = (obj:any): FormData => {
    const formData = new FormData()
    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Array) {
        obj[key].forEach((item:any) => {
          if (item !== null && item !== undefined) {
            if (typeof item === 'object') {
              formData.append(key, JSON.stringify(item))
            } else {
              formData.append(key, item)
            }
          }
        })
        return
      }
      if (obj[key] !== null && obj[key] !== undefined) {
        formData.append(key, obj[key])
      }
      // console.log(key + ':' + obj[key])
    })
    return formData
  }

  /**
   * 获取URL传递参数
   * @param key
   * @returns
   */
  getUrlValue = (key:string):string => {
    const url = window.location.href // 获取当前url
    // eslint-disable-next-line camelcase
    const dz_url = url.split('#')[0] // 获取#/之前的字符串
    // eslint-disable-next-line camelcase
    const cs_str:string = dz_url.split('?')[1] // 获取?之后的参数字符串
    // eslint-disable-next-line camelcase
    const cs_arr:string[] = cs_str.split('&') // 参数字符串分割为数组
    let _value = ''
    for (let i = 0; i < cs_arr.length; i++) { // 遍历数组，拿到json对象
      if (cs_arr[i].split('=')[0] === key) {
        _value = cs_arr[i].split('=')[1]
      }
    }
    return _value
  }

  private S4 = ():string => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }

  guid = ():string => {
    return (this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4())
  }

  IsChinese=(s:string):boolean => {
    // eslint-disable-next-line prefer-regex-literals
    const chineseReg = new RegExp('^[\u4E00-\u9FFF]+$')
    if (!chineseReg.test(s)) {
      return false
    } else {
      return true
    }
  }

  /**
   * 判定是否为数字 true:数值型的，false:非数值型的
   * @param s
   * @returns
   */
  IsNan=(s:string):boolean => {
    return (typeof s === 'number' && !isNaN(s))
  }

  /**
   * 复制文本
   * @param copyText 需要复制的字符
   */
  copyText = (str:string) => {
    const input = document.createElement('textarea')
    document.body.appendChild(input)
    input.value = str
    input.select()
    if (document.execCommand('copy')) {
      document.execCommand('copy')
    }
    document.body.removeChild(input)
    message.success('复制成功')
  }

  /**
     * @param {drag} element 需要移动的元素
     * @param {fun} function 点击触发的事件
     */
   draggable = (drag:any, idx:number, eles:any[], maxX:number, moveFunCallBack?:()=>void, fun?:()=>void) => {
     let startEvt:string, moveEvt:string, endEvt:string
     // 判断是否支持触摸事件
     if ('ontouchstart' in window) {
       startEvt = 'touchstart'
       moveEvt = 'touchmove'
       endEvt = 'touchend'
     } else {
       startEvt = 'mousedown'
       moveEvt = 'mousemove'
       endEvt = 'mouseup'
     }

     drag.style.cursor = 'grab'
     let disX:number, disY:number, left:number, top:number, starX:number, starY:number, oLeft:number, oTop:number
     drag.addEventListener(startEvt, startFun)

     function startFun (event:any) {
       // 阻止页面的滚动，缩放
       event.preventDefault()
       // 兼容IE浏览器
       drag.style.cursor = 'grabbing'
       const e = event || window.event
       oLeft = drag.offsetLeft
       oTop = drag.offsetTop
       // 手指按下时的坐标
       starX = e.touches ? e.touches[0].clientX : e.clientX
       starY = e.touches ? e.touches[0].clientY : e.clientY
       // 手指相对于拖动元素左上角的位置
       disX = starX - drag.offsetLeft
       disY = starY - drag.offsetTop
       // 按下之后才监听后续事件
       document.addEventListener(moveEvt, moveFun)
       document.addEventListener(endEvt, endFun)
     }

     function moveFun (event:any) {
       // 兼容IE浏览器
       const e = event || window.event
       left = (e.touches ? e.touches[0].clientX : e.clientX) - disX
       top = (e.touches ? e.touches[0].clientY : e.clientY) - disY
       let minx = 0
       let maxx = 0
       if (idx > 0) {
         minx = parseFloat(eles[idx - 1].style.left.replace('px', '')) + 6
       } else {
         minx = minx + 3
       }
       if (idx === eles.length - 1) {
         maxx = maxX - 3
       } else {
         maxx = parseFloat(eles[idx + 1].style.left.replace('px', '')) - 6
       }
       // 限制拖拽的X范围，不能拖出屏幕
       if (left < minx) {
         left = minx
       } else if (left > maxx) {
         left = maxx
       } else if (left > document.documentElement.clientWidth - drag.offsetWidth) {
         left = document.documentElement.clientWidth - drag.offsetWidth
       }
       // 限制拖拽的Y范围，不能拖出屏幕
       const miny = 0
       const maxy = 0
       if (top < miny) {
         top = miny
       } else if (top > maxy) {
         top = maxy
       } else if (top > window.innerHeight - drag.offsetHeight) {
         top = window.innerHeight - drag.offsetHeight
       }
       drag.style.left = left + 'px'
       drag.style.top = top + 'px'
       if (moveFunCallBack !== undefined) {
         moveFunCallBack()
       }
     }

     function endFun (event:any) {
       drag.style.cursor = ''
       document.removeEventListener(moveEvt, moveFun)
       document.removeEventListener(endEvt, endFun)
       if (oLeft === drag.offsetLeft && oTop === drag.offsetTop && fun) { // 点击
         if (fun !== undefined) {
           fun()
         }
       }
     }
   }

  /**
   * 下载文件
   * @param url
   */
  download = (url:string) => {
    // 处理图片下载
    const newstring = url.substring(url.length - 4, url.length)
    if (['.png', '.jpg', 'jpeg', '.bmp', '.gif', 'webp', '.psd', '.svg', 'tiff'].indexOf(newstring) !== -1) {
      url = url + '?response-content-type=application/octet-stream'
    }
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', '')
    a.setAttribute('target', '_blank')
    const clickEvent = document.createEvent('MouseEvents')
    clickEvent.initEvent('click', true, true)
    a.dispatchEvent(clickEvent)
  }

  base64ToImg=(base64:string):string => {
    const byteCharacters = atob(
      base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')// 去掉data:image/png;base64
    )
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: undefined
    })
    return URL.createObjectURL(blob)
  }

  base642Url = (data:string):string => {
    const parts = data.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const length = raw.length
    const arr = new Uint8Array(length)
    for (let i = 0; i < length; i++) {
      arr[i] = raw.charCodeAt(i)
    }
    const blob = new Blob([arr], { type: contentType })
    return URL.createObjectURL(blob)
  }
}

const common = new Common()
export default common
