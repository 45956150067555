export default class constants {
  public static readonly ACCESS_TOKEN: string = 'access_token'
  public static readonly USER_INFO: string='userInfo'
  public static readonly MALL_INFO: string='mallInfo'
  public static readonly MALL_SETTING: string='mallSetting'
  public static readonly MENU_CACHE:string ='menus'
  public static readonly ALL_MENU_CACHE:string ='allMenus'
  public static readonly ALL_DICT_CACHE:string ='allDicts'
  public static readonly ALL_OFFICE_CACHE:string ='allOffice'
  public static readonly PROMISSIONS:string ='allPromissions'
  public static readonly IS_DOWNLOAD_ORDERS ='is_download_orders'
  public static readonly GOODS_TYPE = 'goods_type'
  public static readonly ALL_COMPANIES = 'all_companies'
  public static readonly ALL_AREAS = 'all_areas'
  public static readonly TAG_STAR_CACHE = 'tag_star_cache'
  public static readonly TEMPLATE_CACHE = 'template_cache'
}
