/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import config from '@/config'
import pddMallInfoService from './PddMallInfoService'
import { MallCreateRelateRequst, MallListResponse, MallRelateRequest, PddMallInfo, PddMallInfoImpl, PddMallRelation } from './types'
import { PageResponse } from '@/common/types'
import { CompanyRequest } from '../pddTemplate/types'
import router from '@/router'
import { getToken } from '@/server/sys/users'
import constants from '@/common/constant/constants'
import { localCache } from '@/utils/cache'

const pathConfig = {
  pddMallInfoListPath: config.baseUrl + '/api/pdd/mall/list.html',
  pddMallInfoFormPath: config.baseUrl + '/api/pdd/mall/form.html',
  mallInfoPath: config.baseUrl + '/api/pdd/mall/mallInfo.html',
  pddMallInfoSavePath: config.baseUrl + '/api/pdd/mall/save.do',
  pddMallInfoDelPath: config.baseUrl + '/api/pdd/mall/delete.do',
  pddRelateDoUrl: config.baseUrl + '/api/pdd/mall/relate.do', // 建立发起建立关联关系
  pddRelateUrl: config.baseUrl + '/api/pdd/mall/relate.html', // 创建店铺关联关系
  relateListPageUrl: config.baseUrl + '/api/pdd/mall/relate_list_page.html', // 加载与店铺关联的店铺
  relateListUrl: config.baseUrl + '/api/pdd/mall/relate_list.html', // 加载与店铺关联的店铺
  relateInfoUrl: config.baseUrl + '/api/pdd/mall/relate_info.html', // 加载关联店铺信息
  cancellRelateUrl: config.baseUrl + '/api/pdd/mall/cancell_relate.html', // 解除绑定关系
  outsideWaybillUrl: config.baseUrl + '/api/pdd/mall/outside_waybill.html', // 添加电子面单授权地址
  registerOutsideWaybillUrl: config.baseUrl + '/api/pdd/mall/reg_outside_waybill.html', // 注册电子面单授权
  getOutsideWaybillUrl: config.baseUrl + '/api/pdd/mall/get_outside_waybill.html', // 获取已注册电子面单源信息
  delOutsideWaybillUrl: config.baseUrl + '/api/pdd/mall/del_outside_waybill.html', // 删除已注册电子面单源
  mallWaybillDetailsUrl: config.baseUrl + '/api/pdd/mall/mall_waybill_details.html' // 获取电子面单源面单授权情况
}

/**
 * 返回店铺管理列表
 * @param pddMallInfo
 * @returns
 */
const getPddMallInfoListPage = (pddMallInfo:PddMallInfo) => {
  return pddMallInfoService.getPddMallInfoListPage(pathConfig.pddMallInfoListPath, pddMallInfo)
}

/**
 * 获取店铺管理
 * @param id 获取ID
 * @returns
 */
const getPddMallInfoInfo = (id:string) => {
  return pddMallInfoService.getPddMallInfoInfo(pathConfig.pddMallInfoFormPath, id)
}

/**
 * 通过店铺编码获取店铺信息
 * @param mallid
 * @returns
 */
const getPddMallInfoInfox = (mallid:string) => {
  return pddMallInfoService.getPddMallInfoInfox(pathConfig.mallInfoPath, mallid)
}

/**
 * 同步通过店铺编码获取店铺信息
 * @param mallid
 * @returns
 */
const getPddMallInfoInfoxAsync = (mallid:string) => {
  const params:Record<string, string> = { mallid: mallid }
  const result = pddMallInfoService.getRequst<Record<string, string>, PddMallInfo>(params, pathConfig.mallInfoPath)
  return result
}

/**
 * 获取当前用户的店铺信息
 */
const loadCurrnetMallInfo = () => {
  const token = getToken()
  return getPddMallInfoInfox(token.mallId.toString())
}

/**
 * 获取缓存当前用户的店铺信息
 */
const loadCacheCurrnetMallInfo = () => {
  return localCache.getSingleStorage(constants.MALL_INFO) as PddMallInfo
}

/**
 * 保存店铺管理
 * @param pddMallInfo
 * @param callback
 * @returns
 */
const savePddMallInfo = (pddMallInfo:PddMallInfo, callback?:()=>void) => {
  return pddMallInfoService.savePddMallInfo(pddMallInfo, pathConfig.pddMallInfoSavePath, callback)
}

/**
 * 删除店铺管理
 * @param id
 * @param callback
 * @returns
 */
const delPddMallInfo = (id:string, callback?:()=>void) => {
  const pddMallInfo = new PddMallInfoImpl()
  pddMallInfo.id = id
  return pddMallInfoService.delPddMallInfo(pddMallInfo, pathConfig.pddMallInfoSavePath, callback)
}

/**
 * 建立发起建立关联关系
 * @param mallRelateRequest
 * @returns
 */
const doRelate = (mallRelateRequest:MallRelateRequest) => {
  return pddMallInfoService.commonPostRequest<MallRelateRequest, string>(pathConfig.pddRelateDoUrl, mallRelateRequest)
}

/**
 * 创建店铺关联关系
 */
const createRelate = (mallCreateRelateRequst:MallCreateRelateRequst) => {
  return pddMallInfoService.commonPostRequest<MallCreateRelateRequst, string>(pathConfig.pddRelateUrl, mallCreateRelateRequst)
}

/**
 * 加载与店铺关联的分页店铺
 * @param pddMallRelation
 * @returns
 */
const loadRelateListPage = (pddMallRelation:PddMallRelation) => {
  return pddMallInfoService.commonPostRequest<PddMallRelation, PageResponse<PddMallRelation>>(pathConfig.relateListPageUrl, pddMallRelation)
}

/**
 * 加载与店铺关联的店铺
 * @param rt 店铺关联类型 1:主子关系；2：同组关系
 * @returns
 */
const loadRelateList = (rt?:number) => {
  const param:Record<string, number|undefined> = { rt: rt }
  return pddMallInfoService.commonGetRequest<Record<string, number|undefined>, MallListResponse[]>(pathConfig.relateListUrl, param)
}

/**
 * 加载关联店铺信息
 * @param id
 * @returns
 */
const loadRelateInfo = (id:string) => {
  const param:Record<string, string> = { id: id }
  return pddMallInfoService.commonGetRequest<Record<string, string>, PddMallRelation>(pathConfig.relateInfoUrl, param)
}

/**
 * 解除绑定关系
 * @param mallid 被解除绑定关系的店铺ID
 * @returns
 */
const cancellRelate = (mallid:string, callback?:()=>void) => {
  const param:Record<string, string> = { mallid: mallid }
  pddMallInfoService.commonGetRequestNoRetrun<Record<string, string>>(pathConfig.cancellRelateUrl, param, callback)
}

/**
 * 获取添加电子面单授权地址
 * @param v
 * @returns
 */
const getOutsideWaybillService = (v?:string) => {
  const param:Record<string, string|undefined> = { v: v }
  return pddMallInfoService.commonGetRequest<Record<string, string|undefined>, string>(pathConfig.outsideWaybillUrl, param)
}

/**
 * 注册电子面单授权
 * @param code
 * @returns
 */
const registerOutsideWaybill = (code:string) => {
  const param:Record<string, string> = { code: code }
  pddMallInfoService.commonGetRequestNoRetrun<Record<string, string>>(pathConfig.registerOutsideWaybillUrl, param, () => {
    router.push({
      name: 'waybill',
      params: {
        key: '3'
      }
    })
  }, 3)
}

/**
 * 获取已注册电子面单源
 * @returns
 */
const getOutsideWaybill = () => {
  return pddMallInfoService.commonGetRequest<undefined, PddMallInfo[]>(pathConfig.getOutsideWaybillUrl)
}

/**
 * 删除已注册电子面单源
 * @param mallid
 */
const delOutsideWaybill = (mallid:string) => {
  const param:Record<string, string> = { mallid: mallid }
  pddMallInfoService.commonGetRequestNoRetrun<Record<string, string>>(pathConfig.delOutsideWaybillUrl, param)
}

/**
 * 获取电子面单源面单授权情况
 * @param mallid
 */
const getMallWaybillDetails = (mallid:string) => {
  const param:Record<string, string> = { mallId: mallid }
  return pddMallInfoService.commonGetRequest<Record<string, string>, CompanyRequest[]>(pathConfig.mallWaybillDetailsUrl, param)
}

export { getPddMallInfoListPage, getPddMallInfoInfo, getPddMallInfoInfox, getPddMallInfoInfoxAsync, loadCurrnetMallInfo, loadCacheCurrnetMallInfo, savePddMallInfo, delPddMallInfo, doRelate, createRelate, loadRelateListPage, loadRelateList, loadRelateInfo, cancellRelate, getOutsideWaybillService, registerOutsideWaybill, getOutsideWaybill, delOutsideWaybill, getMallWaybillDetails }
