/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import constants from '@/common/constant/constants'
import config from '@/config'
import pddTemplateService from './PddTemplateService'
import { PddTemplate, PddTemplateImpl } from './types'
import { localCache } from '@/utils/cache'

const pathConfig = {
  pddTemplateListPath: config.baseUrl + '/api/pdd/template/list.html',
  templateListPath: config.baseUrl + '/api/pdd/template/templateList.html',
  waybillPath: config.baseUrl + '/api/pdd/template/waybill.html', // 查询单服务订购及面单使用情况
  moveTemplatePath: config.baseUrl + '/api/pdd/template/move.do', // 上下移动电子面单
  changeSenderPath: config.baseUrl + '/api/pdd/template/changeSender.html', // 变更寄件人
  allCompaniesPath: config.baseUrl + '/api/pdd/template/getCompanies.html', // 获取全量快递公司信息
  setDefaultTemplatePath: config.baseUrl + '/api/pdd/template/setDefaultTemplate.html', // 设置默认模板
  cancelDefaultTemplatePath: config.baseUrl + '/api/pdd/template/cancelDefaultTemplate.html', // 取消默认模板
  postTemplatePath: config.baseUrl + '/api/pdd/template/postTemplate.html', // 保存模板信息
  pddTemplateFormPath: config.baseUrl + '/api/pdd/template/form.html',
  pddTemplateSavePath: config.baseUrl + '/api/pdd/template/save.do',
  pddTemplateDelPath: config.baseUrl + '/api/pdd/template/delete.do'
}

/**
 * 返回快递单模板列表
 * @param pddTemplate
 * @returns
 */
const getPddTemplateListPage = (pddTemplate:PddTemplate) => {
  return pddTemplateService.getPddTemplateListPage(pathConfig.pddTemplateListPath, pddTemplate)
}

/**
 * 返回当前用户所有的面单模板
 * @returns
 */
const getTemplateList = () => {
  return pddTemplateService.getTemplateList(pathConfig.templateListPath)
}

/**
 * 获取缓存中的模板信息
 * @returns
 */
const getCacheTemplateList = () => {
  return localCache.getSingleStorage(constants.TEMPLATE_CACHE) as PddTemplate[]
}

/**
 * 查询单服务订购及面单使用情况
 * @param wpCode 快递公司编码
 * @param wdCode 快递网点编码
 * @returns
 */
const getWaybill = (wpCode:string, wdCode:string) => {
  return pddTemplateService.getWaybill(wpCode, wdCode, pathConfig.waybillPath)
}

/**
 * 获取快递单模板
 * @param id 获取ID
 * @returns
 */
const getPddTemplateInfo = (id:string) => {
  return pddTemplateService.getPddTemplateInfo(pathConfig.pddTemplateFormPath, id)
}

/**
 * 保存快递单模板
 * @param pddTemplate
 * @param callback
 * @returns
 */
const savePddTemplate = (pddTemplate:PddTemplate, callback?:()=>void) => {
  return pddTemplateService.savePddTemplate(pddTemplate, pathConfig.pddTemplateSavePath, callback)
}

/**
 * 变更电子面单模板
 * @param id 电子面单模板ID
 * @param senderid 发件人ID
 * @param success
 */
const changeSenderFun = (id:string, senderid:string, success?:()=>void) => {
  pddTemplateService.changeSender(id, senderid, pathConfig.changeSenderPath, success)
}

/**
 * 删除快递单模板
 * @param id
 * @param callback
 * @returns
 */
const delPddTemplate = (id:string, callback?:()=>void) => {
  const pddTemplate = new PddTemplateImpl()
  pddTemplate.id = id
  return pddTemplateService.delPddTemplate(pddTemplate, pathConfig.pddTemplateDelPath, callback)
}

/**
 * 上下移动电子面单模板
 * @param id 需要移动的模板ID
 * @param mtag 上移或下移 mtag>0 上移 mtag<0 下移
 * @param success
 */
const movePddTemplate = (id:string, mtag:number, success:()=>void) => {
  pddTemplateService.movePddTemplate(id, mtag, pathConfig.moveTemplatePath, success)
}

/**
 * 获取所有快递公司信息
 * @returns
 */
const getAllCompanies = () => {
  return pddTemplateService.getAllCompanies(pathConfig.allCompaniesPath)
}

/**
 * 获取缓存中快递公司信息
 * @returns
 */
const getCacheCompanyies = () => {
  return localCache.getSingleStorage(constants.ALL_COMPANIES)
}

/**
 * 设置默认电子面单模板
 * @param tid 默认模板ID
 * @param success
 */
const setDefaultTemplate = (tid:string, success?:()=>void) => {
  pddTemplateService.setDefaultTemplate(pathConfig.setDefaultTemplatePath, tid, success)
}

/**
 * 取消默认电子面单模板
 * @param tid 模板ID
 * @param success
 */
const cancelDefaultTemplate = (tid:string, success?:()=>void) => {
  pddTemplateService.cancelDefaultTemplate(pathConfig.cancelDefaultTemplatePath, tid, success)
}

/**
 * 保存电子面单模板信息
 * @param pddTemplate
 * @param success
 */
const postTemplate = (pddTemplate:PddTemplate, success?:()=>void) => {
  pddTemplateService.postTemplate(pddTemplate, pathConfig.postTemplatePath, success)
}

export { getPddTemplateListPage, getTemplateList, getCacheTemplateList, getWaybill, getPddTemplateInfo, savePddTemplate, changeSenderFun, delPddTemplate, movePddTemplate, getAllCompanies, getCacheCompanyies, setDefaultTemplate, cancelDefaultTemplate, postTemplate }
