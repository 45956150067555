import { Decrypt, Encrypt } from '../secret'

enum CacheType {
  Local,
  Session
}

class CacheUtils {
  storage: Storage
  constructor (type: CacheType) {
    this.storage = type === CacheType.Local ? localStorage : sessionStorage
  }

  setStorage = (key: string, value: unknown) => {
    let _v = ''
    if (typeof value === 'object') {
      _v = JSON.stringify(value)
      _v = 'obj-' + _v
    } else {
      _v = 'str-' + value
    }
    const ls = this.storage
    if (ls) {
      ls.setItem(key, Encrypt(_v))
    }
  }

  setSingleStorage = (key: string, value: unknown) => {
    // const userToken = getToken()
    // if (userToken !== null && userToken !== undefined) {
    //   key = userToken.access_token.substring(0, 32) + '_' + key
    // }
    this.setStorage(key, value)
  }

  getStorage = (key: string) => {
    const ls = this.storage
    if (ls) {
      let v = Decrypt(ls.getItem(key) + '')
      if (!v) {
        return
      }
      if (v.indexOf('obj-') === 0) {
        v = v.slice(4)
        return JSON.parse(v)
      } else if (v.indexOf('str-') === 0) {
        return v.slice(4)
      }
    }
  }

  getSingleStorage =(key: string) => {
    // const userToken = getToken()
    // if (userToken !== null && userToken !== undefined) {
    //   key = userToken.access_token.substring(0, 32) + '_' + key
    // }
    return this.getStorage(key)
  }

  clearStorage = () => {
    const ls = this.storage
    ls.clear()
  }
}

const localCache = new CacheUtils(CacheType.Local)
const sessionCache = new CacheUtils(CacheType.Session)

export { localCache, sessionCache }
