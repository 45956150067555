/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import constants from '@/common/constant/constants'
import BaseService from '@/common/service/BaseService'
import { PageResponse } from '@/common/types'
import config from '@/config'
import { message } from 'ant-design-vue'
import { SysDict } from './types'
import { localCache } from '@/utils/cache'

class DictService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  loadDicts (dictPath:string): void {
    const result = super.getRequst<null, SysDict[]>(null, dictPath)
    result.then(res => {
      if (res.code === 200) {
        if (res.data != null && res.data.length > 0) {
          // console.log(res.data)
          localCache.setSingleStorage(constants.ALL_DICT_CACHE, res.data)
        }
      } else {
        // message.error(res.message)
        console.log(res.massage)
        // return []
      }
    }).catch(error => {
      console.log(error)
    })
  }

  async getSysDictListPage (sysDictListPath:string, sysDict:SysDict):Promise<PageResponse<SysDict>> {
    const sysDictList = super.postRequstPage<SysDict, PageResponse<SysDict>>(sysDict, sysDictListPath)
    let sysDictListResponse:PageResponse<SysDict> = { pageNum: 1, pageSize: config.pageSize, totalPages: 0, totalSize: 0, content: undefined }
    await sysDictList.then(res => {
      if (res.code === 200) {
        sysDictListResponse = res.data
      }
    })
    return sysDictListResponse
  }

  async getSysDictInfo (sysDictFormPath:string, id:string):Promise<SysDict|null> {
    let sysDict:SysDict|null = null
    const params:Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, SysDict>(params, sysDictFormPath)
    await result.then(res => {
      if (res.code === 200) {
        sysDict = res.data
      } else {
        message.error(res.message)
        sysDict = null
      }
    })
    return sysDict
  }

  saveSysDict (sysDict:SysDict, sysDictSavePath:string, callback?:()=>void):void {
    const result = super.postRequst<SysDict, string>(sysDict, sysDictSavePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  delSysDict (sysDict:SysDict, sysDictDelPath:string, callback?:()=>void):void {
    const result = super.postRequst<SysDict, string>(sysDict, sysDictDelPath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }
}
const dictService = new DictService()

export default dictService
