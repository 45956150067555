import { BaseRequest } from '@/common/types'
import { SysOffice, SysOfficeImpl } from '../../office/types'

interface LoginState {
  username: string;
  password: string;
  captcha: string,
  key: string,
  rememberme:boolean;
  mobileLogin: boolean;
}

interface ReLoginState {
  username: string;
  password: string;
  repassword: string;
  captcha: string,
  key: string,
  rememberme:boolean;
  mobileLogin: boolean;
}

interface TokenResponse {
  id:string;
  loginName:string;
  name:string;
  email:string;
  phone:string;
  mobile:string;
  photo:string;
  userType?:string;
  company?:SysOffice;
  office:SysOffice;
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  token_expir: number;
  timestep: number;
  mallId: number;
}
interface UserInfo{
  loginName:string;
  name:string;
  email:string;
  phone:string;
  mobile:string;
  photo:string;
}
interface SysUser extends BaseRequest{
  id?:string;
  loginName:string;
  name:string;
  password: string;
  newPassword?:string;
  confirmNewPassword?: string;
  email:string;
  phone:string;
  mobile:string;
  photo:string;
  company:SysOffice|null;
  office:SysOffice|null;
  no:string;
  userType:string|undefined;
  loginIp?:string;
  loginDate?:string;
  loginFlag:string;
  roleIdList:string[]|null;
  remarks:string;
  ext01?:string;
  mallId?:number;
}

class SysUserImpl implements SysUser {
  id?: string | undefined;
  loginName: string;
  name: string;
  password: string;
  newPassword:string;
  confirmNewPassword: string;
  email: string;
  phone: string;
  mobile: string;
  photo: string;
  company: SysOffice | null;
  office: SysOffice | null;
  no: string;
  userType: string | undefined;
  loginFlag: string;
  roleIdList: string[] | null;
  remarks: string;
  photoFile?: any;
  ext01?:string;
  mallId?:number;
  constructor () {
    this.loginName = ''
    this.name = ''
    this.password = ''
    this.newPassword = ''
    this.confirmNewPassword = ''
    this.email = ''
    this.phone = ''
    this.mobile = ''
    this.photo = ''
    this.company = new SysOfficeImpl()
    this.office = new SysOfficeImpl()
    this.no = ''
    this.userType = undefined
    // this.loginDate= '';
    this.loginFlag = '1'
    this.roleIdList = []
    this.remarks = ''
    this.photoFile = null
    this.ext01 = ''
  }
}

export { LoginState, ReLoginState, TokenResponse, UserInfo, SysUser, SysUserImpl }
