/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import BaseService from '@/common/service/BaseService'
import { PageResponse } from '@/common/types'
import config from '@/config'
import { message } from 'ant-design-vue'
import { PddMallInfo } from './types'

class PddMallInfoService extends BaseService {
  async getPddMallInfoListPage (pddMallInfoListPath:string, pddMallInfo:PddMallInfo):Promise<PageResponse<PddMallInfo>> {
    const pddMallInfoList = super.postRequstPage<PddMallInfo, PageResponse<PddMallInfo>>(pddMallInfo, pddMallInfoListPath)
    let pddMallInfoListResponse:PageResponse<PddMallInfo> = { pageNum: 1, pageSize: config.pageSize, totalPages: 0, totalSize: 0, content: undefined }
    await pddMallInfoList.then(res => {
      if (res.code === 200) {
        pddMallInfoListResponse = res.data
      }
    })
    return pddMallInfoListResponse
  }

  async getPddMallInfoInfo (pddMallInfoFormPath:string, id:string):Promise<PddMallInfo|null> {
    let pddMallInfo:PddMallInfo|null = null
    const params:Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, PddMallInfo>(params, pddMallInfoFormPath)
    await result.then(res => {
      if (res.code === 200) {
        pddMallInfo = res.data
      } else {
        message.error(res.message)
        pddMallInfo = null
      }
    })
    return pddMallInfo
  }

  async getPddMallInfoInfox (mallInfoPath:string, mallid:string):Promise<PddMallInfo|null> {
    let pddMallInfo:PddMallInfo|null = null
    const params:Record<string, string> = { mallid: mallid }
    const result = super.getRequst<Record<string, string>, PddMallInfo>(params, mallInfoPath)
    await result.then(res => {
      if (res.code === 200) {
        pddMallInfo = res.data
      } else {
        message.error(res.message)
        pddMallInfo = null
      }
    })
    return pddMallInfo
  }

  async getCusMallInfo (cusMallInfoPath:string):Promise<PddMallInfo[]|undefined> {
    let pddMallInfos:PddMallInfo[]|undefined
    const result = super.postRequst<null, PddMallInfo[]>(null, cusMallInfoPath)
    await result.then(res => {
      if (res.code === 200) {
        pddMallInfos = res.data
      } else {
        message.error(res.message)
      }
    })
    return pddMallInfos
  }

  savePddMallInfo (pddMallInfo:PddMallInfo, pddMallInfoSavePath:string, callback?:()=>void):void {
    const result = super.postRequst<PddMallInfo, string>(pddMallInfo, pddMallInfoSavePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  delPddMallInfo (pddMallInfo:PddMallInfo, pddMallInfoDelPath:string, callback?:()=>void):void {
    const result = super.postRequst<PddMallInfo, string>(pddMallInfo, pddMallInfoDelPath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }
}

const pddMallInfoService = new PddMallInfoService()

export default pddMallInfoService
