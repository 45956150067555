import config from '@/config'
import { downloadRequest, ywzRequest } from '@/utils/axios'
import store from '@/store'
import { BaseRequest, PageResponse } from '../types'
import { message } from 'ant-design-vue'

abstract class BaseService {
  baseUrl: string
  timeout: number
  constructor () {
    this.baseUrl = config.baseUrl
    this.timeout = config.timeout
  }

  /**
   * 上传表单无附件
   * @param data
   * @param reqUrl
   * @returns
   */
  async postRequst<D, T> (data: D, reqUrl: string) {
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传表单无附件
   * @param data
   * @param reqUrl
   * @returns
   */
  postRequstNormal<D, T> (data: D, reqUrl: string) {
    return ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传表单无附件可等待10分钟
   * @param data
   * @param reqUrl
   * @returns
   */
  async postLongWaitRequst<D, T> (data: D, reqUrl: string) {
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      hearderType: 4,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传表单无附件,支持空参数
   * @param data
   * @param reqUrl
   * @returns
   */
  async postRequstx<D, T> (reqUrl: string, data?: D, isloadding?:boolean) {
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      interceptors: {
        requestInterceptors (res) {
          if (isloadding === undefined || isloadding) {
            store.state.loadding = true // 在请求发出之前进行一些操作
          }
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          if (isloadding === undefined || isloadding) {
            store.state.loadding = false// 在这里对返回的数据进行处理
          }
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 查询分页
   * @param data
   * @param reqUrl
   * @returns
   */
  async postRequstPage<D extends BaseRequest, T> (data: D, reqUrl: string) {
    reqUrl = reqUrl + '?pageIndex=' + data.pageIndex + '&pageSize=' + data.pageSize
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传对象中包含附件，请使用此方法
   * @param data
   * @param reqUrl
   * @returns
   */
  async postRequstAttach<D, T> (data: D, reqUrl: string) {
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      hearderType: 1,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          // res.data = common.objectToFormData(data)
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传附件，请使用此方法
   * @param data
   * @param reqUrl
   * @returns
   */
  async postRequstAttachFiles<D, T> (data: D, reqUrl: string) {
    return await ywzRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      data,
      hearderType: 3,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传form表单
   * @param data
   * @param reqUrl
   * @returns
   */
  async postDownloadRequst<D, T> (data: D, reqUrl: string) {
    return await downloadRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      responseType: 'blob',
      data,
      hearderType: 2,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          // res.data = common.objectToFormData(data)
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  /**
   * 上传form表单
   * @param data
   * @param reqUrl
   * @returns
   */
  async postDownloadRequstx<D, T> (data: D, reqUrl: string) {
    return await downloadRequest<D, T>({
      url: reqUrl,
      method: 'POST',
      responseType: 'blob',
      data,
      interceptors: {
        requestInterceptors (res) {
          store.state.loadding = true // 在请求发出之前进行一些操作
          console.log('接口请求拦截')
          // res.data = common.objectToFormData(data)
          return res
        },
        responseInterceptors (result) {
          store.state.loadding = false// 在这里对返回的数据进行处理
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  getRequst<D, T> (data: D, reqUrl: string) {
    return ywzRequest<D, T>({
      url: reqUrl,
      method: 'GET',
      data,
      interceptors: {
        requestInterceptors (res) {
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  requst = <D, T> (data: D, _reqUrl: string, _method: string) => {
    return ywzRequest<D, T>({
      url: _reqUrl,
      method: _method,
      data,
      interceptors: {
        requestInterceptors (res) {
          console.log('接口请求拦截')
          return res
        },
        responseInterceptors (result) {
          console.log('接口响应拦截')
          return result
        }
      }
    })
  }

  commonPostRequestNoReturn<D> (reqpath:string, param?:D, callback?:()=>void, isloadding?:boolean): void {
    const result = this.postRequstx<D, string>(reqpath, param, isloadding)
    result.then(res => {
      if (res.code === 200) {
        if (res.message !== null && res.message.length > 0) {
          message.success(res.message)
        }
        if (callback !== undefined) {
          callback()
        }
      } else {
        message.error(res.message)
      }
    })
  }

  async commonPostRequest<D, T> (reqpath:string, param?:D, isloadding?:boolean): Promise<T|undefined> {
    const result = this.postRequstx<D, T>(reqpath, param, isloadding)
    let rst:T|undefined
    await result.then(res => {
      if (res.code === 200) {
        // message.success(res.message)
        rst = res.data
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
      }
    })
    return rst
  }

  /**
   * 上传对象中包换附件
   * @param reqpath
   * @param param
   * @param fcallback
   * @returns
   */
  async commonPostRequstAttach<D, T> (reqpath:string, param:D, fcallback?:()=>void): Promise<T|undefined> {
    const result = this.postRequstAttach<D, T>(param, reqpath)
    let rst:T|undefined
    await result.then(res => {
      if (res.code === 200) {
        // message.success(res.message)
        rst = res.data
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
        if (fcallback !== undefined) {
          fcallback()
        }
      }
    })
    return rst
  }

  /**
   * 上传附件组
   * @param reqpath
   * @param param
   * @param fcallback
   * @returns
   */
  async commonPostRequstAttachFiles<D, T> (reqpath:string, param:D, fcallback?:()=>void): Promise<T|undefined> {
    const result = this.postRequstAttachFiles<D, T>(param, reqpath)
    let rst:T|undefined
    await result.then(res => {
      if (res.code === 200) {
        // message.success(res.message)
        rst = res.data
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
        if (fcallback !== undefined) {
          fcallback()
        }
      }
    })
    return rst
  }

  async commonGetRequest<D, T> (reqpath:string, param?:D, fcallback?:()=>void): Promise<T|undefined> {
    const result = this.getRequst<D|undefined, T|undefined>(param, reqpath)
    let rst:T|undefined
    await result.then(res => {
      if (res.code === 200) {
        rst = res.data
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
        if (fcallback !== undefined) {
          fcallback()
        }
      }
    })
    return rst
  }

  /**
   * 公用分页请求方法
   * @param reqpath
   * @param param
   * @returns
   */
  async commonPostListPage<D extends BaseRequest, T> (reqpath:string, param:D):Promise<PageResponse<T>|undefined> {
    const result = this.postRequstPage<D, PageResponse<T>>(param, reqpath)
    let response:PageResponse<T>|undefined
    await result.then(res => {
      if (res.code === 200) {
        response = res.data
      }
    })
    return response
  }

  commonGetRequestNoReturn<D> (reqpath:string, param?:D, callback?:()=>void): void {
    const result = this.getRequst<D|undefined, string>(param, reqpath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
      }
    })
  }

  commonPostRequestNoRetrun<D> (reqpath:string, param?:D, callback?:()=>void):void {
    const result = this.postRequst<D|undefined, string>(param, reqpath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message)
        if (callback !== undefined) {
          callback()
        }
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
      }
    })
  }

  commonGetRequestNoRetrun<D> (reqpath:string, param?:D, callback?:()=>void, secands?:number):void {
    const result = this.getRequst<D|undefined, string>(param, reqpath)
    result.then(res => {
      if (res.code === 200) {
        if (secands !== undefined && secands > 0) {
          message.success(res.message, secands, () => {
            if (callback !== undefined) {
              callback()
            }
          })
        } else {
          message.success(res.message)
          if (callback !== undefined) {
            callback()
          }
        }
      } else {
        if (res.message !== null && res.message.length > 0) {
          message.error(res.message)
        }
      }
    })
  }
}

export default BaseService
