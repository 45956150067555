import { checkUserStatus } from '@/server/sys/users'
import { localCache } from '@/utils/cache'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'indexpage',
    meta: {
      title: '首页'
    },
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
    // component: () => import(/* webpackChunkName: "about" */ '../views/HCLoginView.vue')
  },
  {
    path: '/loginRedirect.html',
    name: 'loginRedirect',
    meta: {
      title: '正在跳转....'
    },
    component: () => import('../views/loginRedirect.vue')
  },
  {
    path: '/waybillRedirect.html',
    name: 'waybillRedirect',
    meta: {
      title: '正在跳转....'
    },
    component: () => import('../views/waybillRedirect.vue')
  },
  {
    path: '/relateRedirect',
    name: 'relateRedirect',
    meta: {
      title: '正在跳转....'
    },
    component: () => import('../views/relateRedirect.vue')
  },
  {
    path: '/sys',
    name: 'sys',
    component: () => import('@/views/IndexView.vue'),
    children: [
      {
        path: 'main',
        name: 'main',
        children: [
          {
            path: 'index.html',
            name: 'index',
            meta: {
              title: '首页'
            },
            component: () => import('../views/HomeView.vue')
          }
        ]
      },
      {
        path: 'menu',
        name: 'menu',
        children: [
          {
            path: 'list.html',
            name: 'menuList',
            meta: {
              title: '菜单管理' // 设置页面是否需要使用缓存
            },
            component: () => import('../views/sys/menu/menuList.vue')
          }
        ]
      },
      {
        path: 'user',
        name: 'user',
        children: [
          {
            path: 'list.html',
            name: 'userList',
            meta: {
              title: '用户管理'
            },
            component: () => import('../views/sys/user/userList.vue')
          },
          {
            path: 'userinfo.html',
            name: 'userInfo',
            meta: {
              title: '用户详情'
            },
            component: () => import('../views/sys/user/userInfo.vue')
          }
        ]
      },
      {
        path: 'role',
        name: 'role',
        children: [
          {
            path: 'list.html',
            name: 'roleList',
            meta: {
              title: '角色管理'
            },
            component: () => import('../views/sys/role/roleManage.vue')
          }
        ]
      },
      {
        path: 'dict',
        name: 'dict',
        children: [
          {
            path: 'list.html',
            name: 'dictList',
            meta: {
              title: '字典管理'
            },
            component: () => import('../views/sys/dicts/dictManage.vue')
          }
        ]
      },
      {
        path: 'office',
        name: 'office',
        children: [
          {
            path: 'list.html',
            name: 'officeList',
            meta: {
              title: '机构管理'
            },
            component: () => import('../views/sys/office/officeManage.vue')
          }
        ]
      },
      {
        path: 'log',
        name: 'log',
        children: [
          {
            path: 'conlog.html',
            name: 'consoleList',
            meta: {
              title: '控制台'
            },
            component: () => import('../views/ConsoleView.vue')
          }
        ]
      },
      {
        path: 'help',
        name: 'help',
        children: [
          {
            path: 'helpManage.html',
            name: 'helpManage',
            meta: {
              title: '帮助手册'
            },
            component: () => import('../views/sys/help/helpManage.vue')
          }
        ]
      },
      {
        path: 'version',
        name: 'version',
        children: [
          {
            path: 'versionManage.html',
            name: 'versionManage',
            meta: {
              title: '系统版本'
            },
            component: () => import('../views/sys/version/versionManage.vue')
          }
        ]
      },
      {
        path: 'pddsetting',
        name: 'pddsetting',
        children: [
          {
            path: 'pddsetting.html',
            name: 'pddsettingManage',
            meta: {
              title: '打单软件配置'
            },
            component: () => import('../views/pdd/pddSetting.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/gen',
    name: 'gen',
    component: () => import('@/views/IndexView.vue'),
    children: [
      {
        path: 'cf',
        name: 'cf',
        children: [
          {
            path: 'generate.html',
            name: 'generateCode',
            meta: {
              title: '代码生成器'
            },
            component: () => import('../views/gen/generateCode.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/pdd',
    name: 'pdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
    children: [
      {
        path: 'main',
        name: 'main',
        children: [
          {
            path: 'index.html',
            name: 'index',
            meta: {
              title: '首页'
            },
            component: () => import('../views/pddIndexView.vue')
          }
        ]
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/pdd/order/orderIndex.vue'),
        children: [
          {
            path: 'printOrders.html',
            name: 'printOrderList',
            meta: {
              title: '待发货订单'
            },
            component: () => import('../views/pdd/order/printOrderList.vue')
            // component: () => import('../views/pdd/orderList.vue')
          },
          {
            path: 'printFulOrderList.html',
            name: 'printFulOrderList',
            meta: {
              title: '待发货订单--跨境全托管发货单'
            },
            component: () => import('../views/pdd/order/printFulOrderList.vue')
            // component: () => import('../views/pdd/orderList.vue')
          },
          {
            path: 'postOrders.html',
            name: 'postOrders',
            meta: {
              title: '已发货订单'
            },
            component: () => import('../views/pdd/order/postedOrders.vue')
          },
          {
            path: 'repostedOrders.html',
            name: 'repostedOrders',
            meta: {
              title: '重新发货'
            },
            component: () => import('../views/pdd/order/repostedOrders.vue')
          },
          {
            path: 'failOrders.html',
            name: 'failOrders',
            meta: {
              title: '交易关闭'
            },
            component: () => import('../views/pdd/order/failOrders.vue')
          }
        ]
      },
      {
        path: 'template',
        name: 'template',
        children: [
          {
            path: 'templatelist.html',
            name: 'templateList',
            meta: {
              title: '快递单模板'
            },
            component: () => import('../views/pdd/template/pddTemplateManage.vue')
          },
          {
            path: 'templateDictList.html',
            name: 'templateDictList',
            meta: {
              title: '快递模板字典'
            },
            component: () => import('../views/pdd/template/pddTemplateDictManage.vue')
          },
          {
            path: 'templateItemDictList.html',
            name: 'templateItemDictList',
            meta: {
              title: '快递模板自定义字典'
            },
            component: () => import('../views/pdd/template/pddTemplateCustomDictManage.vue')
          },
          {
            path: 'templateEdit.html',
            name: 'templateEdit',
            meta: {
              title: '快递模板设计'
            },
            component: () => import('../views/pdd/template/pddTemplateEdit.vue')
          }
        ]
      },
      {
        path: 'dispatch',
        name: 'dispatch',
        children: [
          {
            path: 'pddDispatchTemplateDictList.html',
            name: 'pddDispatchTemplateDictList',
            meta: {
              title: '发货单模板字典'
            },
            component: () => import('../views/pdd/dispatch/pddDispatchTemplateDictManage.vue')
          },
          {
            path: 'dispatchlist.html',
            name: 'dispatchlist',
            meta: {
              title: '发货单模板'
            },
            component: () => import('../views/pdd/dispatch/pddDispatchTemplateManage.vue')
          },
          {
            path: 'dispatchEdit.html',
            name: 'dispatchEdit',
            meta: {
              title: '设计发货单模板'
            },
            component: () => import('../views/pdd/dispatch/pddDispatchTemplateEdit.vue')
          }
        ]
      },
      {
        path: 'customOrder',
        name: 'customOrder',
        component: () => import('../views/pdd/customOrder/customOrderIndex.vue'),
        children: [{
          path: 'form.html',
          name: 'customOrderForm',
          meta: {
            title: '录入订单'
          },
          component: () => import('../views/pdd/customOrder/cusOrderAdd.vue')
        }, {
          path: 'unPrintCustomOrders.html',
          name: 'unPrintCustomOrders',
          meta: {
            title: '未打印订单'
          },
          component: () => import('../views/pdd/customOrder/unPrintCustomOrders.vue')
        }, {
          path: 'printCustomOrders.html',
          name: 'printCustomOrders',
          meta: {
            title: '已打印订单'
          },
          component: () => import('../views/pdd/customOrder/printCustomOrders.vue')
        }, {
          path: 'contractManage.html',
          name: 'contractManage',
          meta: {
            title: '常用联系人'
          },
          component: () => import('../views/pdd/customOrder/contractManage.vue')
        }]
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('../views/pdd/setting/settingIndex.vue'),
        children: [
          {
            path: 'shopsRelate.html',
            name: 'shopsRelate',
            meta: {
              title: '多店铺管理'
            },
            component: () => import('../views/pdd/setting/mallRelateManage.vue')
          },
          {
            path: 'waybill.html',
            name: 'waybill',
            meta: {
              title: '电子面单授权'
            },
            component: () => import('../views/pdd/setting/waybillManage.vue')
          },
          {
            path: 'addressSetting.html',
            name: 'addressSetting',
            meta: {
              title: '发货地址设置'
            },
            component: () => import('../views/pdd/setting/addressSetting.vue')
          },
          {
            path: 'baseSetting.html',
            name: 'baseSetting',
            meta: {
              title: '基础设置'
            },
            component: () => import('../views/pdd/setting/baseSetting.vue')
          },
          {
            path: 'pddUserSetting.html',
            name: 'pddUserSetting',
            meta: {
              title: '子账号设置'
            },
            component: () => import('../views/pdd/setting/pddUserSetting.vue')
          },
          {
            path: 'orderSetting.html',
            name: 'orderSetting',
            meta: {
              title: '订单设置'
            },
            component: () => import('../views/pdd/setting/orderSetting.vue')
          }
        ]
      },
      {
        path: 'topic',
        name: 'topic',
        children: [
          {
            path: 'topicList.html',
            name: 'topicList',
            meta: {
              title: '意见反馈'
            },
            // component: () => HomeView
            // component: () => import('../views/HomeView.vue')
            component: () => import('../views/pdd/topic/topicManage.vue')
          }
        ]
      }
    ]
  }

]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('printservice'),
  routes
})

router.beforeEach((to, from, next) => {
  // 检查用户是否已经登录
  if (to.path === '/login' || to.path === '/loginRedirect.html' || to.path === '/waybillRedirect.html' || to.path === '/relateRedirect' || to.path === '/dkxxadd.html') {
    next()
  } else {
    checkUserStatus(() => {
      next()
    }, () => {
      // 清空本地缓存
      localCache.clearStorage()
      next('/login')
    })
  }
})

export default router
