import constants from '@/common/constant/constants'
import config from '@/config'
import dictService from './DictService'
import { SysDict, SysDictImpl } from './types'
import { localCache } from '@/utils/cache'

const pathConfig = {
  dictPath: config.baseUrl + '/api/sys/common/allDict.html',
  sysDictListPath: config.baseUrl + '/api/sys/sysDict/list.html',
  sysDictFormPath: config.baseUrl + '/api/sys/sysDict/form.html',
  sysDictSavePath: config.baseUrl + '/api/sys/sysDict/save.do',
  sysDictDelPath: config.baseUrl + '/api/sys/sysDict/delete.do'
}

const loadAllDicts = () => {
  dictService.loadDicts(pathConfig.dictPath)
}
/**
 * 获取字典集合
 * @param key
 * @returns
 */
const findDict = (key:string) => {
  const dicts = localCache.getSingleStorage(constants.ALL_DICT_CACHE)
  return dicts.filter((item: SysDict) => item.type === key)
}

/**
 * 获取字典项
 * @param type
 * @param key
 * @returns
 */
const findDictKV = (type:string, key: string) => {
  const dicts = localCache.getSingleStorage(constants.ALL_DICT_CACHE)
  return dicts.filter((item: SysDict) => item.type === type && item.value === key)[0]
}

/**
 * 返回字典项列表
 * @param sysDict
 * @returns
 */
const getSysDictListPage = (sysDict:SysDict) => {
  return dictService.getSysDictListPage(pathConfig.sysDictListPath, sysDict)
}

/**
 * 获取字典项
 * @param id 获取ID
 * @returns 角色详情
 */
const getSysDictInfo = (id:string) => {
  return dictService.getSysDictInfo(pathConfig.sysDictFormPath, id)
}

/**
 * 保存字典项
 * @param role
 * @param callback
 * @returns
 */
const saveSysDict = (sysDict:SysDict, callback?:()=>void) => {
  return dictService.saveSysDict(sysDict, pathConfig.sysDictSavePath, callback)
}

/**
 * 删除字典项
 * @param id
 * @param callback
 * @returns
 */
const delSysDict = (id:string, callback?:()=>void) => {
  const sysDict = new SysDictImpl()
  sysDict.id = id
  return dictService.delSysDict(sysDict, pathConfig.sysDictSavePath, callback)
}

export { loadAllDicts, findDict, findDictKV, getSysDictListPage, getSysDictInfo, saveSysDict, delSysDict }
