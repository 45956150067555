import config from '@/config'
import common from '../common'
import { message, notification } from 'ant-design-vue'
import { PddPrintDataResponse } from '@/server/pdd/pddPrint/types'
import { PddDispatchDataResponse, PddDispatchTemplate } from '@/server/pdd/pddDispatchTemplate/types'
import { PddTemplate } from '@/server/pdd/pddTemplate/types'
import store from '@/store'

class PrinterUtils {
   /**
   * 返回拼多多打印插件配置数据
   * @returns 返回拼多多打印插件配置数据
   */
   initPddPrint = () => {
     return {
       socket: new WebSocket(config.pddPrintService),
       printWSServer: config.pddPrintService,
       printersInfo: {
         getPrintersRequestID: common.guid(),
         printers: [],
         defaultPrinter: ''
       },
       setPrinterConfig: {
         setPrinterConfigRequestID: common.guid()
       },
       doPrintConfig: {
         doPrintConfigRequestID: common.guid(),
         ERPId: common.guid()
       },
       version: '1.0',
       tasklist: []
     }
   }

  printContents=[]

  pddPrinter:any=null

  /**
   * 拼多多打印组件初始化
   * @param success
   * @param failure
   */
  doPddConnect = (success?:(res:any)=>void, failure?:(error:any)=>void) => {
    if (this.pddPrinter !== null) {
      if (this.pddPrinter.socket.readyState === 1) {
        if (success !== undefined) {
          success(this.pddPrinter)
        }
      } else if (this.pddPrinter.socket.readyState === 0) {
        this.initPddConnect(success, failure)
      } else if (this.pddPrinter.socket.readyState === 2) {
        if (failure !== undefined) {
          failure('连接正在进行关闭！！！')
        }
      } else if (this.pddPrinter.socket.readyState === 3) {
        this.initPddConnect(success, failure)
      } else {
        if (failure !== undefined) {
          failure('连接失败！！！')
        }
      }
    } else {
      this.initPddConnect(success, failure)
    }
  }

  /**
   * 初始化拼多多插件
   * @param success
   * @param failure
   */
  initPddConnect = (success?:(res:any)=>void, failure?:(error:any)=>void) => {
    this.pddPrinter = this.initPddPrint()
    if (typeof (WebSocket) === 'undefined') {
      message.warn('您的浏览器不支持socket')
    } else {
      // 打开打印机
      this.pddPrinter.socket.onopen = function (event:any) {
        this.getPrinters()
        // 监听消息
        this.pddPrinter.socket.onmessage = function (event:any) {
          console.log('Client received a message', event)
          const responseData = JSON.parse(event.data)
          if (responseData.requestID === this.pddPrinter.printersInfo.getPrintersRequestID) {
            this.pddPrinter.printersInfo.printers = []
            if (responseData.printers !== undefined && responseData.printers.length > 0) {
              responseData.printers.forEach((item:any) => {
                this.pddPrinter.printersInfo.printers.push(item.name)
              })
            }
            this.pddPrinter.printersInfo.defaultPrinter = responseData.defaultPrinter
            if (success !== undefined) {
              success(event)
            }
          }
          if (responseData.requestID === this.pddPrinter.doPrintConfig.doPrintConfigRequestID) {
            notification.success({
              message: '操作提醒',
              description:
                '打印信息成功发送给打印了'
            })
            if (success !== undefined) {
              success(event)
            }
          }
        }
        // 监听Socket的关闭
        this.pddPrinter.socket.onclose = function (event:any) {
          console.log('Client notified socket has closed', event)
        }
      }
      this.pddPrinter.socket.onerror = function (event:any) {
        // message.error('请启动或下载拼多多打印组件！')
        if (failure !== null && failure !== undefined) {
          failure(event)
        }
      }
    }
  }

  /**
   * 重新加载官方打印组件
   * @param success 执行成功后回调方法
   * @param error 执行失败后回调方法
   */
  reConnectPrinter=(success?:(res: any)=>void, error?:(error:any)=>void) => {
    this.doPddConnect((res: any) => {
      store.state.isInitPrinter = 1
      if (success !== undefined) {
        success(res)
      }
    }, (error: any) => {
      store.state.isInitPrinter = -1
      if (error !== undefined) {
        error(error)
      }
    })
  }

  doPrintOrder=(pddPrintDataResponse:PddPrintDataResponse[], pddTemplate:PddTemplate) => {
    const documents: any[] = []
    pddPrintDataResponse.forEach(item => {
      const contents = []
      const printeData = JSON.parse(item.printData)
      contents.push(printeData)
      if (item.pddTemplateCustomList !== undefined && item.pddTemplateCustomList.length > 0) {
        item.pddTemplateCustomList.forEach(i => {
          if (i.ext05 !== '0') {
            i.value = config.templateImg + '?key=' + i.value
          }
        })
        const cusTemplate = {
          data: {
            list: item.pddTemplateCustomList
          },
          templateURL: pddTemplate.cusTemplateUrl
        }
        contents.push(cusTemplate)
      }
      const document = {
        contents: contents,
        documentID: item.waybillCode
      }
      documents.push(document)
    })
    this.doSetPrinterConfig(pddTemplate)
    const tasks = {
      documents: documents,
      notifyType: ['print'],
      preview: false,
      previewType: 'image',
      printer: this.pddPrinter.printersInfo.defaultPrinter,
      taskID: common.guid()
    }
    // console.log(tasks)
    this.doprint(tasks)
  }

  doDispatchOrder=(pddDispatchDataResponses:PddDispatchDataResponse[], pddDispatchTemplate:PddDispatchTemplate) => {
    const documents: any[] = []
    pddDispatchDataResponses.forEach(item => {
      const contents = []
      const cusTemplate = {
        data: {
          height: item.height,
          list: item.pddTemplateCustomList,
          width: item.width
        },
        templateURL: item.templateUrl
      }
      contents.push(cusTemplate)
      const document = {
        contents: contents //,
        // documentID: item.waybillCode
      }
      documents.push(document)
    })

    const tasks = {
      documents: documents,
      notifyType: ['print'],
      preview: false,
      previewType: 'image',
      printer: pddDispatchTemplate.ext04,
      taskID: common.guid()
    }
    // console.log(tasks)
    this.doprint(tasks)
  }

    /**
   * 获取打印机清单
   */
    getPrinters =() => {
      // 加载打印机信息
      const request = {
        cmd: 'getPrinters',
        requestID: this.pddPrinter.printersInfo.getPrintersRequestID,
        version: this.pddPrinter.version
      }
      this.pddPrinter.socket.send(JSON.stringify(request))
    }

    /**
     * 设置默认打印机
     * @param template
     */
    doSetPrinterConfig=(template:PddTemplate) => {
      const request =
          {
            cmd: 'setPrinterConfig',
            requestID: this.pddPrinter.setPrinterConfig.setPrinterConfigRequestID,
            version: this.pddPrinter.version,
            printer: {
              name: template.ext03,
              PrintTopLogo: template.printTopLogo === 1,
              PrintBottomLogo: template.printBottomLogo === 1,
              horizontalOffset: template.horizontalOffset,
              verticalOffset: template.verticalOffset
            }
          }
      this.pddPrinter.printersInfo.defaultPrinter = template.ext03
      this.pddPrinter.socket.send(JSON.stringify(request))
    }

    /**
     * 设置发货单打印机
     * @param pddDispatchTemplate
     */
    doSetDispatchPrinterConfig=(pddDispatchTemplate:PddDispatchTemplate) => {
      const request =
        {
          cmd: 'setPrinterConfig',
          requestID: this.pddPrinter.setPrinterConfig.setPrinterConfigRequestID,
          version: this.pddPrinter.version,
          printer: {
            name: pddDispatchTemplate.ext04,
            horizontalOffset: pddDispatchTemplate.horizontalOffset,
            verticalOffset: pddDispatchTemplate.verticalOffset
          }
        }
      this.pddPrinter.printersInfo.defaultPrinter = pddDispatchTemplate.ext04
      this.pddPrinter.socket.send(JSON.stringify(request))
    }

    doprint =(tasks:any) => {
      const request =
      {
        ERPId: this.pddPrinter.doPrintConfig.ERPId,
        cmd: 'print',
        requestID: this.pddPrinter.doPrintConfig.doPrintConfigRequestID,
        task: tasks,
        version: this.pddPrinter.version
      }
      this.pddPrinter.socket.send(JSON.stringify(request))
    }
}

const pddPrinter = new PrinterUtils()
export default pddPrinter
