import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
// import App from '@/views/IndexView.vue'
import router from './router'
import 'ant-design-vue/dist/reset.css'
import * as antIcons from '@ant-design/icons-vue'
import { isValidKey } from '@/utils/icon'
import store from './store'
import checkAuth from './server/sys/promission'
import { Decrypt, Encrypt } from './utils/secret'
import config from './config'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { createPinia } from 'pinia'
dayjs.locale('zh-cn')

const pinia = createPinia()
const app = createApp(App)

Object.keys(antIcons).forEach(key => {
  if (isValidKey(key, antIcons)) {
    app.component(key, antIcons[key])
  }
})

app.config.globalProperties.$antIcons = antIcons
app.config.globalProperties.$store = store
app.config.globalProperties.$AES_Encrypt = Encrypt // 全局加密
app.config.globalProperties.$AES_Decrypt = Decrypt // 全局解密

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  const title:any = to.meta.title
  if (title) {
    if (title !== null && title !== undefined) {
      document.title = config.systemname + ' - ' + title
    }
  }
  next()
})

app.directive('permission', (el, binding, vnode) => {
  // binding.value=>使用v-permission绑定的值
  // 与后端返回的数组进行比对，如果后端返回的数组中的值包含binding.value
  // 则有权限，该按钮展示，否则隐藏
  if (!checkAuth(binding.value)) {
    el.parentNode && el.parentNode.removeChild(el)
  }
  // 这将被作为 `mounted` 和 `updated` 调用
}).use(router).use(Antd).use(pinia).mount('#app')
