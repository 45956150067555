import { createStore } from 'vuex'

export default createStore(
  {
    // 状态
    state: {
      loadding: false,
      cacheViews: ['indexCompont', 'menuList', 'roleManage', 'dictManage', 'officeManage', 'userManage', 'userInfo', 'printOrders', 'PddTemplateManage', 'ConsoleManage', 'PddTemplateDictManage'],
      isInitPrinter: 0,
      showFloatButton: 1,
      userType: '4'
    },
    // 状态修改方法
    // 使用store.commit()来调用
    mutations: {
      showloadding (state, load) {
        state.loadding = load
      },
      addCacheViews (state, viewName:any) {
        state.cacheViews.push(viewName)
      },
      editIsInitPrinter (state, _isInitPrinter) {
        state.isInitPrinter = _isInitPrinter
      },
      editShowFloatButton (state, flotButton) {
        state.showFloatButton = flotButton
      },
      editUserType (state, userType) {
        state.userType = userType
      }
    },
    // 可以用store.dispatch来调用
    actions: {
      setloadding (context, load) {
        context.commit('showloadding', load)
      },
      setIsInitPrinter (context, _isInitPrinter) {
        context.commit('editIsInitPrinter', _isInitPrinter)
      },
      setShowFloatButton (context, _flotButton) {
        context.commit('editShowFloatButton', _flotButton)
      },
      setUserType (context, _userType) {
        context.commit('editUserType', _userType)
      }
    },
    getters: {
      isloading: (state) => {
        return state.loadding
      },
      getIsInitPrinter: (state) => {
        return state.isInitPrinter
      },
      getShowFloatButton: (state) => {
        return state.showFloatButton
      },
      getUserType (state) {
        return state.userType
      }
    }
  }
)
