/**
 * Copyright &copy; 2012-2014 sharprain All rights reserved.
 */
import { BaseRequest } from '@/common/types'
import { PddTemplateCustom } from '../../pddTemplateCustom/types'

export interface PddTemplate extends BaseRequest{
     id: string;
templateName: string;// template_name
            templateType: number|null;// template_type
companyName: string;// company_name
companyCode: string;// company_code
backgroupImg: string;// backgroup_img
transportAddress: string;// transport_address
city: string;// 寄件人-城市
detail: string;// 寄件人-详细地址
district: string;// 寄件人-区
province: string;// 寄件人省
town: string;// 寄件人街道
country: string;// 寄件人国家/地区
mobile: string;// 寄件人手机号
name: string;// 寄件人姓名
phone: string;// 寄件人固定电话
            ismain: number|null;// 是否为默认模板
templateurl: string;// 模板地址
printTopLogo: number|null;// 打印顶部logo
printBottomLogo: number|null;// 打印底部logo
horizontalOffset: number;// 水平平移
verticalOffset: number;// 垂直平移
cusTemplateUrl: string;// 自定义模板路径
            width: number;// 单位px
            height: number;// 单位px
ownerId: string;// owner_id
            pxwidth: number;// 像素width
            pxheight: number;// 像素height
            cusheight: number;// 自定义区域高度
            cusTop: number;
            ext03: string; // 默认打印机

            sourceCode:string;// 电子面单来源编码

            wdCode:string; // 网点编码
            wdName:string; // 网点名称
            wdAddress:string; // 网点地址
            goodsType:string; // 商品类型
            productType:string; // 产品类型
            pakageType:string; // 包装类型
            sortcode:number|null;
            senderId:string;
            branchAccounts?:any;
            pddTemplateCustoms:PddTemplateCustom[];
            stdrate:number;// 设计视图与打印模板比例

}

export class PddTemplateImpl implements PddTemplate {
    id: string;
templateName: string;// template_name
            templateType: number|null;// template_type
companyName: string;// company_name
companyCode: string;// company_code
backgroupImg: string;// backgroup_img
transportAddress: string;// transport_address
city: string;// 寄件人-城市
detail: string;// 寄件人-详细地址
district: string;// 寄件人-区
province: string;// 寄件人省
town: string;// 寄件人街道
country: string;// 寄件人国家/地区
mobile: string;// 寄件人手机号
name: string;// 寄件人姓名
phone: string;// 寄件人固定电话
            ismain: number|null;// 是否为默认模板
templateurl: string;// 模板地址
printTopLogo: number|null;// 打印顶部logo
printBottomLogo: number|null;// 打印底部logo
horizontalOffset: number;// 水平平移
verticalOffset: number;// 垂直平移
cusTemplateUrl: string;// 自定义模板路径
width: number;// 单位px
height: number;// 单位px
ownerId: string;// owner_id
pxwidth: number;// 像素width
pxheight: number;// 像素height
cusheight: number;// 自定义区域高度
stdrate: number;// 设计视图与打印模板比例
cusTop: number;
            ext03: string; // 默认打印机

            sourceCode:string;// 电子面单来源编码
            wdCode:string;
            wdName:string;
            wdAddress:string;
            goodsType:string;
            productType:string;
            pakageType:string;
            sortcode:number|null;
            senderId:string;
            pddTemplateCustoms:PddTemplateCustom[];
            constructor () {
              this.id = ''
              this.templateName = ''
              this.templateType = null
              this.companyName = ''
              this.companyCode = ''
              this.backgroupImg = ''
              this.transportAddress = ''
              this.city = ''
              this.detail = ''
              this.district = ''
              this.province = ''
              this.town = ''
              this.country = ''
              this.mobile = ''
              this.name = ''
              this.phone = ''
              this.ismain = null
              this.templateurl = ''
              this.printTopLogo = null
              this.printBottomLogo = null
              this.horizontalOffset = 0
              this.verticalOffset = 0
              this.cusTemplateUrl = ''
              this.width = 0
              this.height = 0
              this.ownerId = ''
              this.pxwidth = 0
              this.pxheight = 0
              this.cusheight = 0
              this.cusTop = 0
              this.ext03 = ''

              this.sourceCode = ''
              this.wdCode = ''
              this.wdName = ''
              this.wdAddress = ''
              this.goodsType = ''
              this.productType = ''
              this.pakageType = ''
              this.sortcode = null
              this.senderId = ''
              this.pddTemplateCustoms = []
              this.stdrate = 1
            }
}

export interface CompanyRequest{
  available:number;
  id:number;
  logisticsCompany:string;
  code:string;
  enable:boolean;
  active:boolean;
  branchAccounts:any[]
}
