import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_spin, {
      spinning: _ctx.store.state.loadding,
      tip: "Loading...",
      class: "load-spin"
    }, null, 8, ["spinning"]),
    _createVNode(_component_router_view)
  ], 64))
}