import { BaseRequest } from '@/common/types'

export interface SysOffice extends BaseRequest{
  id:string;
  parentId:string;// 父级编号
  parentIds:string; // 所有父级编号
  sysArea:string;// 归属区域
  code:string;// 机构编码
  name:string;// 机构名称
  sort:string;// 排序
  type:string;// 机构类型（1：公司；2：部门；3：小组）
  grade:string;// 机构等级（1：一级；2：二级；3：三级；4：四级）
  address:string; // 联系地址
  zipCode:string; // 邮政编码
  master:string; // 负责人
  phone:string; // 电话
  fax:string; // 传真
  email:string; // 邮箱
  useable:string; // 是否可用
  primaryPerson:string; // 主负责人
  deputyPerson:string// 副负责人
}

export class SysOfficeImpl implements SysOffice {
  id: string;
  parentId: string;
  parentIds: string;
  sysArea: string;
  code: string;
  name: string;
  sort: string;
  type: string;
  grade: string;
  address: string;
  zipCode: string;
  master: string;
  phone: string;
  fax: string;
  email: string;
  useable: string;
  primaryPerson: string;
  deputyPerson: string;

  constructor () {
    this.id = ''
    this.parentId = ''
    this.parentIds = ''
    this.sysArea = ''
    this.code = ''
    this.name = ''
    this.sort = '50'
    this.type = ''
    this.grade = ''
    this.address = ''
    this.zipCode = ''
    this.master = ''
    this.phone = ''
    this.fax = ''
    this.email = ''
    this.useable = '1'
    this.primaryPerson = ''
    this.deputyPerson = ''
  }
}
