import BaseService from '@/common/service/BaseService'
import { PageResponse } from '@/common/types'
import config from '@/config'
import { message } from 'ant-design-vue'
import { SysOffice } from './types'

class OfficeService extends BaseService {
  allOffices:SysOffice[]
  constructor () {
    super()
    this.allOffices = new Array<SysOffice>()
  }

  async getAllOffices (officePath:string):Promise<SysOffice[]> {
    this.allOffices = []
    const result = super.postRequst<null, SysOffice[]>(null, officePath)
    await result.then(res => {
      if (res.code === 200) {
        if (res.data != null && res.data.length > 0) {
          res.data.forEach((item: SysOffice, index: number) => {
            this.allOffices.push(item)
          })
        }
      } else {
        message.error(res.message)
      }
    }).catch(error => {
      message.error(error)
    })
    return this.allOffices
  }

  async getSysOfficeListPage (sysOfficeListPath:string, sysOffice:SysOffice):Promise<PageResponse<SysOffice>> {
    const sysOfficeList = super.postRequstPage<SysOffice, PageResponse<SysOffice>>(sysOffice, sysOfficeListPath)
    let sysOfficeListResponse:PageResponse<SysOffice> = { pageNum: 1, pageSize: config.pageSize, totalPages: 0, totalSize: 0, content: undefined }
    await sysOfficeList.then(res => {
      if (res.code === 200) {
        sysOfficeListResponse = res.data
      }
    })
    return sysOfficeListResponse
  }

  async getSysOfficeInfo (sysOfficeFormPath:string, id:string):Promise<SysOffice|null> {
    let sysOffice:SysOffice|null = null
    const params:Record<string, string> = { id: id }
    const result = super.getRequst<Record<string, string>, SysOffice>(params, sysOfficeFormPath)
    await result.then(res => {
      if (res.code === 200) {
        sysOffice = res.data
      } else {
        message.error(res.message)
        sysOffice = null
      }
    })
    return sysOffice
  }

  saveSysOffice (sysOffice:SysOffice, sysOfficeSavePath:string, callback?:()=>void):void {
    const result = super.postRequst<SysOffice, string>(sysOffice, sysOfficeSavePath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  delSysOffice (sysOffice:SysOffice, sysOfficeDelPath:string, callback?:()=>void):void {
    const result = super.postRequst<SysOffice, string>(sysOffice, sysOfficeDelPath)
    result.then(res => {
      if (res.code === 200) {
        message.success(res.message, 3, () => {
          if (callback !== undefined) {
            callback()
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }
}

const officeService = new OfficeService()
export default officeService
