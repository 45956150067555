import { getToken } from '@/server/sys/users'
import Request from './Request'
import type { RequestConfig } from './types'
import common from '../common'

const request = new Request({
  baseURL: '',
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => {
      console.log('实例请求拦截器.....')
      // config.headers = { 'Content-Type': 'multipart/form-data, charset=UTF-8' }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8' }
      const tokenRes = getToken()
      let _token = ''
      if (tokenRes !== undefined) {
        _token = tokenRes.access_token
      }
      config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8', token: _token }
      return config
    },
    // 响应拦截器
    responseInterceptors: result => {
      console.log('实例响应拦截器.....')
      return result
    }
  }
})

/**
 * 等待10分钟
 */
const requestLongWait = new Request({
  baseURL: '',
  timeout: 1000 * 60 * 10,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => {
      console.log('实例请求拦截器.....')
      // config.headers = { 'Content-Type': 'multipart/form-data, charset=UTF-8' }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8' }
      const tokenRes = getToken()
      // console.log(tokenRes)
      let _token = ''
      if (tokenRes !== undefined) {
        _token = tokenRes.access_token
      }
      config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8', token: _token }
      return config
    },
    // 响应拦截器
    responseInterceptors: result => {
      console.log('实例响应拦截器.....')
      return result
    }
  }
})

const requestAttach = new Request({
  baseURL: '',
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => {
      config.data = common.objectToFormData(config.data)
      // const formData = new FormData()
      // config.data.forEach((file:any) => {
      //   formData.append('files', file)
      // })
      // config.data = formData
      console.log('实例form请求拦截器.....')
      // config.headers = { 'Content-Type': 'multipart/form-data, charset=UTF-8' }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8' }
      const tokenRes = getToken()
      // console.log(tokenRes)
      let _token = ''
      if (tokenRes !== undefined) {
        _token = tokenRes.access_token
      }
      config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data;charset=utf-8', token: _token }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/mixed;charset=utf-8', token: _token }
      // config.headers = { 'Access-Control-Allow-Origin': '*', token: _token }
      return config
    },
    // 响应拦截器
    responseInterceptors: result => {
      console.log('实例form响应拦截器.....')
      return result
    }
  }
})

const requestAttachFiles = new Request({
  baseURL: '',
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => {
      // config.data = common.objectToFormData(config.data)
      const formData = new FormData()
      config.data.forEach((file:any) => {
        formData.append('files', file)
      })
      config.data = formData
      console.log('实例form请求拦截器.....')
      const tokenRes = getToken()
      let _token = ''
      if (tokenRes !== undefined) {
        _token = tokenRes.access_token
      }
      config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data;charset=utf-8', token: _token }
      return config
    },
    // 响应拦截器
    responseInterceptors: result => {
      console.log('实例form响应拦截器.....')
      return result
    }
  }
})

const requestForm = new Request({
  baseURL: '',
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => {
      config.data = common.objectToFormData(config.data)
      // console.log(config.data)
      console.log('实例form请求拦截器.....')
      // config.headers = { 'Content-Type': 'multipart/form-data, charset=UTF-8' }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json;charset=utf-8' }
      const tokenRes = getToken()
      console.log(tokenRes)
      let _token = ''
      if (tokenRes !== undefined) {
        _token = tokenRes.access_token
      }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data;charset=utf-8', token: _token }
      // config.headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/mixed;charset=utf-8', token: _token }
      config.headers = { 'Access-Control-Allow-Origin': '*', token: _token }
      return config
    },
    // 响应拦截器
    responseInterceptors: result => {
      console.log('实例form响应拦截器.....')
      return result
    }
  }
})

interface YWZRequestConfig<T> extends RequestConfig {
  hearderType?:number;
  data?: T
}
interface YWZResponse<T> {
  [x: string]: any
  code: number
  message: string
  data: T
}
/**
 * @description: 函数的描述
 * @interface D 请求参数的interface
 * @interface T 响应结构的intercept
 * @param {YWZRequestConfig} config 不管是GET还是POST请求都使用data
 * @returns {Promise}
 */
const ywzRequest = <D, T = any>(config: YWZRequestConfig<D>) => {
  const { method = 'GET' } = config
  if (method === 'get' || method === 'GET') {
    config.params = config.data
  }
  if (config.hearderType === 1) {
    return requestAttach.request<YWZResponse<T>>(config)
  } else if (config.hearderType === 2) {
    return requestForm.request<YWZResponse<T>>(config)
  } else if (config.hearderType === 3) {
    return requestAttachFiles.request<YWZResponse<T>>(config)
  } else if (config.hearderType === 4) {
    return requestLongWait.request<YWZResponse<T>>(config)
  } else {
    return request.request<YWZResponse<T>>(config)
  }
}

const downloadRequest = <D, T = any>(config: YWZRequestConfig<D>) => {
  const { method = 'GET' } = config
  if (method === 'get' || method === 'GET') {
    config.params = config.data
  }
  return requestForm.request<T>(config)
}
export { ywzRequest, downloadRequest }
