import { BaseRequest } from '@/common/types'

export interface SysDict extends BaseRequest{
  id:string;
  value:string;
  label:string;
  type:string;
  description:string;
  sort:number;
  parent?:string;
}

export class SysDictImpl implements SysDict {
  id: string;
  value: string;// 数据值
  label: string;// 标签名
  type: string;// 类型
  description: string;// 描述
  sort: number;// 排序（升序）
  parent: string;// 父级编号
  constructor () {
    this.id = ''
    this.value = ''
    this.label = ''
    this.type = ''
    this.description = ''
    this.sort = 50
    this.parent = ''
  }
}
