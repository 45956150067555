// index.js
export default {
  // baseUrl: 'http://127.0.0.1:5500', // 测试接口域名
  baseUrl: 'https://dd.jialianyuncang.cn:30002', // 正式接口域名
  appUrl: 'https://kcb.jialianyuncang.cn', // 系统应用地址
  appLoginPath: '/printservice/login', // 系统登录地址
  // attachServer: 'http://127.0.0.1:8100/attach', // 附件服务地址
  attachServer: 'https://dd.jialianyuncang.cn:30002/attach', // 正式 附件服务地址
  timeout: 100000,
  Encrypt_Key: '1234567890123456',
  Encrypt_Iv: '113&45c78_9X1234',
  filesize: 15, // 单位：M
  pageSize: 20,
  noimg: '/static/images/no-img.jpg',
  defaultImg: 'https://joeschmoe.io/api/v1/random',
  logoImg: '/static/images/logo.png',
  firstImg: '/static/images/PDD130.jpg',
  stdImg: '/static/images/PDD180.jpg',
  pddSecureImg: '/static/images/pddSecure.png',
  pddBindShopHelpImg: '/static/images/pdd_bind_shop_help.png',
  templateImg: 'https://dd.jialianyuncang.cn:30002/common/base64otimg.html',
  systemname: '快速开发平台',
  QQ: '360397067',
  pddPrintService: 'ws://127.0.0.1:5000', // 拼多多打印组件接口服务
  sysMsgService: 'ws://dd.jialianyuncang.cn:30002/msgserver', // 系统消息日志服务
  pddPrintServiceMall: 'https://fuwu.pinduoduo.com/service-market/service-detail?detailId=47031', // 拼多多打单软件续费地址
  downloadPrinterPath: 'http://meta.pinduoduo.com/api/one/app/v1/lateststable?appId=com.xunmeng.pddprint&platform=windows&subType=main', // 拼多多打印组件地址
  // downloadStdTemplatePath: '/upload/template/标准格式.xls',
  // downloadSimpleTemplatePath: '/upload/template/极简格式.xls',
  // downloadAITemplatePath: '/upload/template/智能识别地址格式.xls',
  // downloadSaleTemplatePath: '/upload/template/全网销量订单模板.xls',
  excelDownloadArray: [
    { name: '标准Excel格式下载', url: '/static/file/标准格式.xls', filename: '标准格式' },
    { name: '智能识别地址Excel格式下载', url: '/static/file/智能识别地址格式.xls', filename: '智能识别地址格式' },
    { name: '极简Excel格式下载', url: '/static/file/极简格式.xls', filename: '极简格式' },
    { name: '发货人自定义Excel格式下载', url: '/static/file/发货人自定义格式.xls', filename: '发货人自定义格式' }
  ],
  contractDownloadArray: {
    senderTemplate: [
      { name: '发货地址标准版下载', url: '/static/file/senderstd.xls', filename: '发货地址标准版' },
      { name: '发货地址精简版下载', url: '/static/file/sendersimple.xls', filename: '发货地址精简版' }
      // { name: '发货地址标准版下载', url: '/static/file/发货地址标准版.xls', filename: '发货地址标准版' },
      // { name: '发货地址精简版下载', url: '/static/file/发货地址精简版.xls', filename: '发货地址精简版' }
    ],
    receiverTemplate: [
      { name: '收件地址标准版下载', url: '/static/file/receiverstd.xls', filename: '收件地址标准版' },
      { name: '收件地址精简版下载', url: '/static/file/receiversimple.xls', filename: '收件地址精简版' }
    ]
  },
  exportCusOrderColumns: [
    { label: '订单号', value: 'orderSn' },
    { label: '收件人姓名', value: 'receiverName' },
    { label: '收件人手机', value: 'receiverPhone' },
    { label: '收件人电话', value: 'receiverTel' },
    { label: '收件人省', value: 'receiverProvince' },
    { label: '收件人市', value: 'receiverCity' },
    { label: '收件人区', value: 'receiverDistrict' },
    { label: '收件人详细地址', value: 'receiverDetail' },
    { label: '发件人姓名', value: 'sender' },
    { label: '发件人手机', value: 'senderPhone' },
    { label: '发件人电话', value: 'senderTel' },
    { label: '发件人省', value: 'senderProvince' },
    { label: '发件人市', value: 'senderCity' },
    { label: '发件人区', value: 'senderDistrict' },
    { label: '发件人地址', value: 'senderDetail' },
    { label: '打印时间', value: 'printDate' },
    { label: '打印快递单次数', value: 'printCount' },
    { label: '打印发货单次数', value: 'dispatchCount' },
    { label: '添加时间', value: 'createdate' },
    { label: '运单号', value: 'trackingNumber' },
    { label: '快递公司', value: 'companyName' },
    { label: '商品名称', value: 'pddOrderGoodsList.goodsName' },
    { label: '商品重量', value: 'pddOrderGoodsList.quality' },
    { label: '商品数量', value: 'pddOrderGoodsList.goodsCount' },
    { label: '商品金额', value: 'payAmount' },
    { label: '商品单价', value: 'pddOrderGoodsList.goodsPrice' },
    { label: '商品SKU编码', value: 'pddOrderGoodsList.skuId' },
    { label: '商品销售属性', value: 'pddOrderGoodsList.goodsSpec' },
    { label: '备注', value: 'remark' }
  ]
}
