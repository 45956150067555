import constants from '@/common/constant/constants'
import { CusTreeNode } from '@/common/types'
import config from '@/config'
import officeService from './OfficeService'
import { SysOffice, SysOfficeImpl } from './types'
import { localCache } from '@/utils/cache'

const pathConfig = {
  officePath: config.baseUrl + '/api/sys/office/treeData.html',
  sysOfficeListPath: config.baseUrl + '/api/sys/office/list.html',
  sysOfficeFormPath: config.baseUrl + '/api/sys/office/form.html',
  sysOfficeSavePath: config.baseUrl + '/api/sys/office/save.do',
  sysOfficeDelPath: config.baseUrl + '/api/sys/office/delete.do'
}

/**
 * 查询系统组织机构
 * @returns 获取所有组织机构
 */
const getAllOffices = () => {
  const allOffices = localCache.getSingleStorage(constants.ALL_OFFICE_CACHE)
  // if (allOffices !== null) {
  //   officeService.getAllOffices(pathConfig.officePath).then(res => {
  //     allOffices = res
  //     common.setStorage(constants.ALL_OFFICE_CACHE, res)
  //   })
  // }
  return allOffices
}

let parentids = ''

const getParentids = (oid:string, isfisrt:boolean) => {
  if (isfisrt) {
    parentids = ''
    isfisrt = false
  }
  const _allOffices = localCache.getSingleStorage(constants.ALL_OFFICE_CACHE)
  const coffice = _allOffices.filter((item:SysOffice) => item.id === oid)[0]
  if (coffice !== undefined && coffice !== null) {
    if (parentids.length > 0) {
      parentids = coffice.id + ',' + parentids
    } else {
      parentids = coffice.id
    }
    getParentids(coffice.parentId, isfisrt)
  }
  return parentids
}

/**
 * 初始化全量组织机构
 */
const initAllOffices = () => {
  officeService.getAllOffices(pathConfig.officePath).then(res => {
    localCache.setSingleStorage(constants.ALL_OFFICE_CACHE, res)
  })
}

/**
 * 重新加载全量组织机构
 */
const reloadAllOffices = (callback?:()=>void) => {
  officeService.getAllOffices(pathConfig.officePath).then(res => {
    localCache.setSingleStorage(constants.ALL_OFFICE_CACHE, res)
    if (callback !== undefined) {
      callback()
    }
  })
}

/**
 * 获取组织机构树形节点
 * @param pid 父ID节点
 * @returns
 */
const getChildrenTreeNodes = (pid:string, type:number) => {
  // console.log(common.getStorage(constants.ALL_OFFICE_CACHE))
  const _allOffices = localCache.getSingleStorage(constants.ALL_OFFICE_CACHE)
  const cusOffice = _allOffices.filter((item:SysOffice) => item.id === pid)[0]
  const treeNode:CusTreeNode = {
    label: cusOffice.name,
    value: cusOffice.id,
    children: undefined
  }
  if (type === -1) {
    const childrenOffice = _allOffices.filter((item:SysOffice) => item.parentId === pid)
    if (childrenOffice !== null && childrenOffice.length > 0) {
      treeNode.children = []
      childrenOffice.forEach((element:SysOffice) => {
        const childOffice = getChildrenTreeNodes(element.id, type)
        treeNode.children!.push(childOffice)
      })
    }
  } else {
    const childrenOffice = _allOffices.filter((item:SysOffice) => item.parentId === pid && item.type === type.toString())
    if (childrenOffice !== null && childrenOffice.length > 0) {
      treeNode.children = []
      childrenOffice.forEach((element:SysOffice) => {
        const childOffice = getChildrenTreeNodes(element.id, type)
        treeNode.children!.push(childOffice)
      })
    }
  }
  return treeNode
}

/**
 * 返回组织机构列表
 * @param sysOffice
 * @returns
 */
const getSysOfficeListPage = (sysOffice:SysOffice) => {
  return officeService.getSysOfficeListPage(pathConfig.sysOfficeListPath, sysOffice)
}

/**
 * 获取组织机构
 * @param id 获取ID
 * @returns
 */
const getSysOfficeInfo = (id:string) => {
  return officeService.getSysOfficeInfo(pathConfig.sysOfficeFormPath, id)
}

/**
 * 保存组织机构
 * @param sysOffice
 * @param callback
 * @returns
 */
const saveSysOffice = (sysOffice:SysOffice, callback?:()=>void) => {
  return officeService.saveSysOffice(sysOffice, pathConfig.sysOfficeSavePath, callback)
}

/**
 * 删除组织机构
 * @param id
 * @param callback
 * @returns
 */
const delSysOffice = (id:string, callback?:()=>void) => {
  const sysOffice = new SysOfficeImpl()
  sysOffice.id = id
  return officeService.delSysOffice(sysOffice, pathConfig.sysOfficeDelPath, callback)
}

export { getAllOffices, reloadAllOffices, initAllOffices, getChildrenTreeNodes, getParentids, getSysOfficeListPage, getSysOfficeInfo, saveSysOffice, delSysOffice }
